<template>
  <v-overlay
    class="overlay"
    :value="page_loading"
  >
    <v-progress-circular
      indeterminate
      size="64"
    />
  </v-overlay>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  data() {
    return {}
  },
  computed: {
    ...mapGetters('app', ['page_loading'])
  }
}
</script>

<style>

</style>
