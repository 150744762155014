import Vue from 'vue'
import VueI18n from 'vue-i18n'

import config from '../configs'

const { locale, availableLocales, fallbackLocale } = config.locales

Vue.use(VueI18n)

const messages = {}

availableLocales.forEach((l) => { messages[l.code] = l.messages })


export const i18n = new VueI18n({
  locale,
  fallbackLocale,
  messages,
  silentTranslationWarn: true
})

i18n.locales = availableLocales


Vue.prototype.$t = (key, ...args) => {
  //eslint-disable-next-line
  const m = key.match(/([a-zA-Z0-9|_|-|\.]+)([\[|\{].+[\]|\}])?/)
  let msg = ''
  if (m && m[1] && m[2]) msg = i18n.t(m[1], eval(`(${m[2]})`))
  else msg =  i18n.t(key, ...args)
  return msg
}

export default i18n
