import Vue from 'vue'
import axios from 'axios'
import { auth } from '../plugins/firebase'

Vue.prototype.$axios = axios

const errorHandler = (err, dispatch) => {
  if (dispatch && err.error && err.message['error'])
    if (err.message['error'])
      dispatch('app/showError', { error: { message: err.message['error'] } }, { root: true })
    else
      dispatch('app/showError', { error: { message: 'message.system_fail' } }, { root: true })
  return err
}

const api = axios.create({
  baseURL: ['8081', '8083'].includes(location.port)? 'http://localhost:8088/web/': 'https://ak55bet-e3959.as.r.appspot.com/web/',
  headers: {
    'Content-Type': 'application/json;charset=utf-8',
    'Access-Control-Allow-Origin': '*'
  }
})
api.interceptors.request.use(async (config) => {
  if (auth.currentUser) {
    await auth.currentUser.getIdToken(true)
    .then(token => {
      config.headers.common['Authorization'] = `bearer ${ token }`
    })
  }
  return config
}, null)
api.interceptors.response.use(function (response) {
  return response
}, function (error) {
  if (error && error.response && error.response.data.message)
    return Promise.reject({ error: true, message: error.response.data.message })
  if (error.message)
    return Promise.reject({ error: true, message: error.message })
  return Promise.reject({ error: true, message: {} })
})

const call = (url, data) => {
  return api.post(url, {data: data})
}

export { axios, api, call, errorHandler }
export default axios
