<template>
  <v-dialog v-model="dialog" persistent max-width="600px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        :outlined="outlined"
        :style="{ width: fullfill ? '100%' : 'auto' }"
        :text="text"
        class="button"
        color="white"
        dark
        v-on="on"
      >
        {{ $t("register.text") }}
      </v-btn>
    </template>
    <v-card color="#283362">
      <v-card-text class="pt-5">
        <div class="d-flex justify-end">
          <v-btn icon>
            <v-icon class="gold--text" @click="close">mdi-close</v-icon>
          </v-btn>
        </div>
        <div class="text-center py-5">
          <img src="/images/logo_ak55bet.png" alt="" style="height: 77px" />
        </div>
        <v-row justify="center" class="my-5">
          <v-col md="10">
            <v-row class="p-3">
              <icon-tab :active="active === 0" text="phone" icon="mdi-phone" />
              <icon-tab
                :active="active === 1"
                icon="mdi-chat-outline"
                text="otp"
              />
              <icon-tab
                :active="active === 2"
                text="account"
                icon="mdi-account"
              />
            </v-row>
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col md="10">
            <div v-show="active === 0">
              <v-form
                ref="phone"
                :disabled="loading.phone"
                @submit.prevent=""
                @keyup.native.enter.prevent="submit"
              >
                <v-text-field
                  v-model="form.phone"
                  label="เบอร์โทร"
                  :rules="rules.phone"
                  dense
                  outlined
                  dark
                  autofocus
                />
                <v-btn
                  :loading="loading.phone"
                  color="#e5b957"
                  dark
                  style="width: 100%"
                  @click="phone_submit"
                  >ยืนยัน</v-btn
                >
              </v-form>
            </div>

            <div v-show="active === 1">
              <v-form
                ref="otp"
                :disabled="loading.otp"
                @submit.prevent=""
                @keyup.native.enter.prevent="submit"
              >
                <div class="pa-3 text-center white--text">
                  รหัส otp กำลังส่งไปยัง {{ form.phone }}
                </div>
                <v-text-field
                  ref="inputOtp"
                  v-model="form.otp"
                  :rules="rules.otp"
                  autofocus
                  color="#e5b957"
                  dark
                  dense
                  label="otp"
                  outlined
                />
                <v-btn
                  @click="otp_submit"
                  :loading="loading.otp"
                  style="width: 100%"
                  color="#e5b957"
                  dark
                  >ยืนยัน</v-btn
                >
              </v-form>
            </div>

            <div v-show="active === 2">
              <v-form
                ref="account"
                :disabled="loading.account"
                @keyup.native.enter.prevent="submit"
                @submit.prevent=""
              >
                <v-row>
                  <v-col>
                    <v-text-field
                      ref="inputPassword"
                      v-model="form.password"
                      :rules="rules.password"
                      autofocus
                      color="#e5b957"
                      dark
                      dense
                      label="รหัสผ่าน"
                      outlined
                      type="password"
                    />
                  </v-col>
                  <v-col>
                    <v-text-field
                      v-model="form.password2"
                      :rules="rules.password2"
                      color="#e5b957"
                      dark
                      dense
                      label="ยืนยันรหัสผ่าน"
                      outlined
                      type="password"
                    />
                  </v-col>
                </v-row>
                <v-select
                  v-model="form.bank_name"
                  :items="bank_options"
                  :rules="rules.bank_name"
                  color="#e5b957"
                  dark
                  dense
                  label="เลือกธนาคารสำหรับฝากถอน"
                  outlined
                />
                <v-text-field
                  v-model="form.bank_number"
                  :rules="rules.bank_number"
                  color="#e5b957"
                  dark
                  dense
                  label="หมายเลขบัญชี"
                  outlined
                />
                <v-text-field
                  v-model="form.reference"
                  :rules="rules.ref"
                  color="#e5b957"
                  dark
                  dense
                  label="รหัสอ้างอิง"
                  outlined
                />
                <v-btn
                  :loading="loading.account"
                  color="#e5b957"
                  dark
                  style="width: 100%"
                  @click="account_submit"
                  >ยืนยัน</v-btn
                >
              </v-form>
            </div>
            <v-btn
              :loading="loading.account"
              class="my-3 gold--text"
              style="width: 100%"
              text
              @click="openLogin"
              >{{ $t("login.text") }}</v-btn
            >
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import IconTab from "../register/IconTab.vue";
import banks from "../../configs/banks";
import validation from "../../helper/validates";
import { mapActions } from "vuex";
//component

export default {
  components: {
    IconTab,
  },
  props: {
    text: {
      type: Boolean,
      default: false,
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    fullfill: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dialog: false,
      active: 0,
      form: {
        phone: "",
        otp: "",
        token: "",
        password: "",
        password2: "",
        bank_name: "",
        bank_number: "",
        reference: "",
      },
      loading: {
        phone: false,
        otp: false,
        account: false,
      },
      rules: {
        phone: [
          validation.required(),
          (v) => /@?0[0-9]{9,15}/.test(v) || "กรุณากรอกเบอร์โทรศัพท์ให้ถูกต้อง",
        ],
        otp: [validation.required(), validation.text(6, 6)],
        password: [validation.text(6, 12)],
        password2: [
          (value) => {
            return this.form.password == value || "password not match";
          },
        ],
        bank_name: [validation.required()],
        bank_number: [validation.required(), validation.numberText(10)],
        ref: [],
      },
      bank_options: banks,
    };
  },
  watch: {
    dialog(val) {
      if (val) {
        setTimeout(() =>{
          this.$refs.phone.resetValidation();
          this.$refs.otp.resetValidation();
          this.$refs.account.resetValidation();
        }, 600)
      }
    },
  },
  methods: {
    ...mapActions("member", ["send_otp", "check_otp", "create_member"]),
    async phone_submit() {
      if (!this.active === 0) return;
      if (!this.$refs.phone.validate()) return;
      this.loading.phone = true;
      await this.send_otp(this.form.phone).then((data) => {
        if (!data.error) {
          this.active = 1;

          setTimeout(() => this.$refs.inputOtp.focus(), 1200);
        }
      });
      this.$refs.otp.resetValidation();
      this.loading.phone = false;
    },
    async otp_submit() {
      if (!this.active === 1) return;
      if (!this.$refs.otp.validate()) return;
      this.loading.otp = true;
      await this.check_otp({ phone: this.form.phone, otp: this.form.otp }).then(
        (data) => {
          if (!data.error) {
            this.form.token = data.token;
            this.active = 2;

            setTimeout(() => this.$refs.inputPassword.focus(), 1200);
          }
        }
      );
      this.$refs.account.resetValidation();
      this.loading.otp = false;
    },
    async account_submit() {
      if (!this.active === 2) return;
      if (!this.$refs.account.validate()) return;
      this.loading.account = true;
      await this.create_member(this.form).then((data) => {
        if (!data.error) {
          this.openLogin();
        }
      });
      this.loading.account = false;
    },
    async submit() {
      if (this.active === 0) await this.phone_submit();
      else if (this.active === 1) await this.otp_submit();
      else if (this.active === 2) await this.account_submit();
    },
    close() {
      this.dialog = false;
      this.active = 0;
      this.$set(this, "form", {
        phone: "",
        otp: "",
        token: "",
        password: "",
        password2: "",
        bank_name: "",
        bank_number: "",
        reference: "",
      });
      this.$set(this, "loading", {
        phone: false,
        otp: false,
        account: false,
      });
    },
    openLogin() {
      this.close();
      this.$emit("openLogin");
    },
  },
};
</script>


<style lang="scss" scoped>
.button {
  background: linear-gradient(
    0deg,
    #01336d 0%,
    #000a30 60%,
    #000000 100%
  ) !important;
  border: 1px solid #fff;
  margin-left: 3px;
  margin-right: 3px;
}
::v-deep .v-card {
  background-color: rgb(40, 51, 98);
}
</style>

