<template>
  <v-dialog v-model="dialog" width="500">
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-bind="attrs" :outlined="outlined" :style="{ width: fullfill ? '100%' : 'auto' }" :text="text"
        class="button" color="primay" dark v-on="on">
        {{ $t("login.text") }}
      </v-btn>
    </template>

    <v-card :loading="isLoading">
      <div class="py-6 px-5">
        <div class="text-center" style="margin-bottom: 50px; margin-top: 50px">
          <img src="/images/logo_ak55bet.png" alt="" style="height: 77px" />
        </div>
        <v-form ref="login" :disabled="isLoading" @keyup.native.enter="authenticate" @submit.prevent="">
          <v-text-field v-model="login.phone" :label="$t('login.phone')" :rules="rules.phone" append-icon="mdi-account"
            autofocus class="mb-0" color="#fff" dark dense outlined></v-text-field>
          <v-text-field v-model="login.password" :append-icon="`mdi-eye${showPassword ? '' : '-off'}`"
            :label="$t('login.password')" :rules="rules.password" :type="showPassword ? 'text' : 'password'"
            class="mb-0" color="#fff" dark dense outlined @click:append="showPassword = !showPassword">
          </v-text-field>
        </v-form>
        <div class="d-flex flex-column">
          <v-btn :disabled="isLoading" :loading="isLoading" class="gold" style="width: 100%"
            @click="authenticate(login)">
            {{ $t("login.text") }}
          </v-btn>
          <v-btn @click="forgetPassword" color="white" text>{{ $t("login.forgetPassword") }}</v-btn>
        </div>

      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import validation from "../../helper/validates";
import { mapActions } from "vuex";
export default {
  props: {
    text: {
      type: Boolean,
      default: false,
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: "#e5b957",
    },
    fullfill: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isLoading: false,
      dialog: false,
      showPassword: false,
      login: {
        phone: "",
        password: "",
      },
      rules: {
        phone: [
          validation.required(),
          (v) => /0[0-9]{9,15}/.test(v) || "กรุณากรอกเบอร์โทรศัพท์ให้ถูกต้อง",
        ],
        otp: [validation.required(), validation.text(6, 6)],
        password: [validation.text(6, 12)],
      },
    };
  },
  created() { },
  mounted() { },
  methods: {
    ...mapActions("auth", { member_login: "login", member_forgetPassword: "forgetPassword" }),
    async authenticate() {
      const validate = this.$refs.login.validate();
      if (!validate) return;

      this.isLoading = true;
      await this.member_login(this.login)
        .then(() => {
          this.$router.push({ name: "game" });
        })
        .catch(() => {
          this.$refs.login.resetValidation();
        });
      this.isLoading = false;
    },
    async forgetPassword() {
      this.isLoading = true
      await this.member_forgetPassword({ phone: this.login.phone })
      this.isLoading = false
    }
  },
  computed: {},
  watch: {
    dialog(val) {
      if (val) {
        this.showPassword = false;
        this.$set(this, "login", {
          phone: "",
          password: "",
        });
      } else this.$refs.login.resetValidation();
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
