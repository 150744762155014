export default {
  common: {
    cancel: 'ยกเลิก',
    submit: 'ตกลง',
    main_wallet: 'กระเป๋าหลัก',
    back: 'กลับ',
    bath: 'บาท',
    logout: 'ออกจากระบบ',
    game: 'เกมส์',
    credit_move: 'ย้ายเครดิตไปยังกระเป๋าถอน',
  },

  message: {
    deposit_future: 'ไม่สามารถแจ้งฝากเงินก่อนวัน',
    deposit_very_old: 'ไม่สามารถแจ้งฝากเงินนานกว่า 2 วัน',
    depsit_has_queue: 'ไม่สามารถแจ้งฝากได้มากกว่า 1 ครั้ง',
    phone_already_exists: 'หมายเลขโทรศัพท์นี้มีผู้ใช้งานแล้ว กรูณาติดต่อผู้ดูแลระบบอีกครั้ง',
    accountant: 'ผลิตภัณฑ์ประเภทบัญชี',
    auth_fail: 'ไม่สามารถเข้าสู่ระบบได้ กรุณาเข้าสู่ระบบอีกครั้ง',
    casino: 'ผลิตภัณฑ์ประเภทคาสิโน',
    casino_text: 'คาสิโนออนไลน์ที่ดี',
    deposit_send_success: 'เพิ่มรายการ ฝาก เรียบร้อย',
    game: 'ผลิตภัณฑ์ประเภทเกมส์',
    history: 'ประวัติ',
    invalid: 'กรุณากรอกข้อมูลไหม่',
    logout_confirm: 'ต้องการออกจากระบบหรือไม่ ?',
    member_not_found: 'ไม่พบบัญชีผู้ใช้ในระบบ',
    not_found: 'พบข้อผิดพลาดในระบบกรุณาตรวจสอบอีกครั้ง',
    not_visit: 'ไม่สามารถเข้าหน้าดังกล่าวได้ กรูณาเข้าระบบอีกครั้ง',
    spots: 'ผลิตภัณฑ์ประเภทกีฬา',
    system_fail: 'พบข้อผิดพลาดในระบบ',
    unauthorized: 'กรุณาเข้าสู่ระบบอีกครั้ง',
    withdraw_send_success: 'เพิ่มรายการ ถอน เรียบร้อย',
    otp_mismatch: 'กรุณากรอก otp ให้ถูกต้อง',
    waiting_again: 'กรุณาทำรายการใหม่ หลังจากนี้ {time} นาที',
    otp_waiting_again: 'กรุณาทำรายการใหม่ หลังจากนี้ {time} นาที',
    can_withdraw: 'สามารถถอนได้ {amount} บาท',
    game_balance: 'เคดิตในเกม {amount} บาท',
    withdraw_waiting_queue_free: 'ไม่สามารถถอนได้เนื่องจากยังมีรายการค้าง',
    deposit_waiting_queue_free: 'ไม่สามารถฝากได้เนื่องจากยังมีรายการค้าง'
  },
  spots: {
    text: 'กีฬา'
  },
  casino: {
    text: 'คาสิโน'
  },
  accountant: {
    text: 'บัญชี'
  },
  history: {
    text: 'ประวัติ'
  },

  login: {
    text: 'เข้าสู่ระบบ',
    phone: 'เบอร์โทรศัพท์',
    password: 'รหัสผ่าน',
    forgetPassword: 'ลืมรหัสผ่าน',
  },

  register: {
    text: 'สมัครสมาชิก'
  },

  home: {
    msg: 'คาสิโนออนไลน์ที่ดี'
  },

  deposit_inform: {
    text: 'แจ้งปัญหาการฝากเงิน',
    history: 'ประวัติการฝาก',
    id: 'ลำดับ',
    date: 'วันเวลา',
    amount: 'จำนวนเงิน',
    comment: 'หมายเหตุ',
    status: 'สถานะ',
  },
  deposit: {
    text: 'ฝากเงิน',
    history: 'ประวัติการฝาก',
    id: 'ลำดับ',
    date: 'วันเวลา',
    amount: 'จำนวนเงิน',
    comment: 'คอมเม้นต์',
    status: 'สถานะ',
    agent_bank_name: 'ธนาคาร',
    agent_name: 'ชื่อบัญชีสำหรับฝาก',
    agent_bank_number: 'หมายเลขบัญชีสำหรับฝาก',
    deposit_date: 'ฝากเงินวันเวลา',
    slip_bank: 'สลิปฝากเงิน',
    notification: 'แจ้งฝากเงิน',
    not_confirm: 'กำลังตรวจสอบข้อมูล',

    cancel: 'ยกเลิก',
    success: 'สำเร็จ',
    confirm: 'อยู่ระหว่างดำเนินการ',
    waiting: 'รอดำเนินการ'
  },
  withdraw: {
    text: 'ถอนเงิน',
    history: 'ประวัติการถอน',
    id: 'ลำดับ',
    date: 'วันเวลา',
    amount: 'ยอดถอนเงิน',
    comment: 'คอมเม้นต์',
    status: 'สถานะ',
    notification: 'แจ้งถอนเงิน',
    note: '* เมื่อถอนแล้วจะโอนยอดคงเหลือในเกมทั้งหมดกลับสู่กระเป๋าหลัก',
    not_complete: 'กำลังตรวจสอบข้อมูล',
    complete: 'สำเร็จ',
    type1: 'มีบิลค้างเดิมพัน กรุณาติดต่อเจ้าหน้าที่',
    type2: 'ทำรายการไม่สำเร็จ กรุณาติดต่อเจ้าหน้าที่',

    cancel: 'ยกเลิก',
    success: 'สำเร็จ',
    confirm: 'อยู่ระหว่างดำเนินการ',
    waiting: 'รอดำเนินการ'


  },

  game: {
    text: 'เกมส์',
    balance: 'เกมส์คงเหลือ',
    sl: 'สล็อตแมชชีน',
    tb: 'เกมส์ตาราง',
    ar: 'อาเขต',
    fh: 'ปลา'
  },
  member: {
    change_password: 'เปลี่ยนรหัสผ่าน',
    bank_name: 'ธนาคาร',
    bank_number: 'หมายเลขบัญชี',
    bank_user: 'ชื่อบัญชี'
  },
  bank: {
    scb: 'ธนาคารไทยพาณิชย์',
    bay:'กรุงศรีอยุธยา',
    bbl:'กรุงเทพ',
    cimb:'ซีไอเอมบีไทย',
    gsb:'ออมสิน',
    kbank:'กสิกรไทย',
    kk:'เกียรตินาคิน',
    ktb:'กรุงไทย',
    lhbank:'แลนด์แอนด์เฮ้าส์',
    tbank:'ธนชาต',
    tmb:'ทหารไทย',
    uob:'ยูโอบี',
    baac:'ธ.ก.ส.',
    ghb:'ธนาคารอาคารสงเคราะห์',
    ttb:'ทหารไทยธนชาต',
    tisco:'ธนาคารทิสโก้'
  },
  coupon: {
    text: 'คูปอง',
    code: 'โค้ดคูปอง',
    comment: 'คอมเม้นต์',
    id: 'รหัส',
    name: 'รายการ',
    status: 'สถานะ',
    history: 'ประวัติการใช้คูปอง',
    send: 'ส่งคูปอง',
  },
  event_sport: {
    text: 'กิจกรรมกีฬา',
    history: 'ประวัติการเข้าร่วมกิจกรรม',
  }
}
