import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

import i18nx from './vue-i18n'
import config from '../configs'
import './axios'

Vue.use(Vuetify);

export default new Vuetify({
  rtl: config.theme.isRTL,
  theme: {
    dark: config.theme.globalTheme === 'dark',
    options: {
      customProperties: true
    },
    themes: {
      dark: config.theme.dark,
      light: config.theme.light
    }
  },
  lang: {
    current: config.locales.locale,
    // To use Vuetify own translations without Vue-i18n comment the next line
    t: (key, ...args) => i18nx.t(key, ...args)

  },
  icons: {
    iconfont: 'mdi', // default - only for display purposes
  },
})
