<template>
  <v-app>
    <!-- Layout component -->
    <component :is="currentLayout" v-if="isRouterLoaded">
      <transition name="fade" mode="out-in">
        <router-view />
      </transition>
    </component>
    <page-progress />
    <v-snackbar
      v-model="toast.show"
      :timeout="toast.timeout"
      :color="toast.color"
      style="margin-bottom: 30px"
      bottom
    >
      {{ toast.label ? $t(toast.label) : "" }}{{ $t(toast.message) }}
      <v-btn
        v-if="toast.timeout === 0"
        color="white"
        text
        @click="toast.show = false"
        >{{ $t("common.close") }}</v-btn
      >
    </v-snackbar>
    <div class="notifications">
      <template>
        <div v-for="noti in notifications" :key="noti.key">
          <v-slide-y-transition>
            <v-card
              v-show="noti.show"
              :color="noti.color"
              class="mb-3 lighten-3 gold--border"
              elevation="0"
            >
              <v-card-text>
                <div>
                  {{ noti.message }}
                </div>
                <div class="d-flex justify-end">
                  <v-btn
                    @click="$router.push(noti.link.to)"
                    text
                    class="caption"
                  >
                    {{ noti.link.text }}
                  </v-btn>
                  <v-btn @click="noti.show = false" icon>
                    <v-icon class="body-2">mdi-close</v-icon>
                  </v-btn>
                </div>
              </v-card-text>
            </v-card>
          </v-slide-y-transition>
        </div>
      </template>
    </div>
  </v-app>
</template>

<script>
import { api } from './plugins/axios'
import { snapMember, snapConfig } from "./plugins/firebase";
import { mapState } from "vuex";
import PageProgress from "./components/common/PageProgress.vue";
//import config from './configs'

// Layouts
import defaultLayout from "./layouts/DefaultLayout";
import errorLayout from "./layouts/ErrorLayout.vue";
import { initCheckAuth } from "./plugins/firebase";

function parseJwt(token) {
  var base64Url = token.split(".")[1];
  var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  var jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
}

export default {
  components: {
    defaultLayout,
    errorLayout,
    PageProgress,
  },
  created() {
    initCheckAuth(async (user) => {
      if (user) {
        //this.$store.commit("auth/setAuthenticated", true, { root: true });
        const token = await user.getIdToken();
        this.$store.commit("auth/setToken", token, { root: true });
        this.$store.commit("auth/setAuthenticated", true, { root: true });
        api.defaults.headers.common["hash"] = this.$store.state.auth.hash;

        const jwt = parseJwt(token);
        const key = jwt.member_id + "_" + jwt.sub;

        if (!this.$store.state.member.isSanp) {
          this.$store.state.member.isSanp = 1;

          snapMember(key, (typeData, d) => {
            if (typeData === "profile") {
              this.$store.commit("member/setProfile", !d ? {} : d, {
                root: true,
              });
            } else {
              const typeFunctions = {
                balance: () => {
                  this.$store.commit("member/setBalance", !d ? {} : d, {
                    root: true,
                  });
                },
                deposits: () => {
                  this.$store.dispatch(
                    "deposit/setDeposits",
                    !d ? [] : Object.values(d),
                    {
                      root: true,
                    }
                  )
                },
                withdraws: () => {
                  this.$store.dispatch(
                    "withdraw/setWithdraws",
                    !d ? [] : Object.values(d),
                    {
                      root: true,
                    }
                  )
                },
                depositQueues: () =>{
                  this.$store.commit(
                    "deposit/setDepositQueues",
                    !d
                      ? []
                      : Object.keys(d).map((k) => {
                          d[k].key = k;
                          return d[k];
                        }),
                    { root: true }
                  )
                },
                removeDepositQueues: () => {
                  this.$store.commit("deposit/removeDepositQueue", d, {
                    root: true,
                  })
                },
                withdrawQueues: () => {
                  this.$store.commit(
                    "withdraw/setWithdrawQueues",
                    !d
                      ? []
                      : Object.keys(d).map((k) => {
                          d[k].key = k;
                          return d[k];
                        }),
                    { root: true }
                  )
                },
                removeWithdrawQueues: () => {
                  this.$store.commit("withdraw/removeWithdrawQueue", d, {
                    root: true,
                  })
                },
                coupons: () => {
                  this.$store.commit("coupon/setCoupons", !d ? [] : d, {
                    root: true,
                  });
                },
                couponQueues: () => {
                  this.$store.commit("coupon/setCouponQueues", !d ? [] : d, {
                    root: true,
                  });
                },
                removeCouponQueues: () => {
                  this.$store.commit("coupon/removeCouponQueue", d, {
                    root: true,
                  });
                },
                sports: () => {
                  this.$store.commit("sport/setSports", !d ? {} : d, {
                    root: true,
                  });
                },
                sportQueues: () => {
                  this.$store.commit("sport/setSportQueues", !d ? [] : d, {
                    root: true,
                  });
                },
                removeSportQueues: () => {
                  this.$store.commit("sport/removeSportQueue", d, {
                    root: true,
                  });
                },
                lotteries: () => {
                  this.$store.commit("lottery/setLotteries", !d ? {} : d, {
                    root: true,
                  });
                },
                lotteryQueues: () => {
                  this.$store.commit("lottery/setLotteryQueues", !d ? [] : d, {
                    root: true,
                  });
                },
                removeLotteryQueues: () => {
                  this.$store.commit("lottery/removeLotteryQueue", d, {
                    root: true,
                  });
                }
              };
              typeFunctions[typeData] && typeFunctions[typeData]();
            }
          });
        }

        if (!this.$store.state.app.isSanp) {
          this.$store.state.app.isSanp = 1;
          snapConfig((config) => {
            if (!config) return;
            if (typeof config.DEPOSIT_OPEN !== 'undefined')
              this.$store.commit(
                "app/setDepositOpen",
                config.DEPOSIT_OPEN || 0,
                { root: true }
              );
            if (typeof config.WITHDRAW_OPEN !== 'undefined')
              this.$store.commit(
                "app/setWithdrawOpen",
                config.WITHDRAW_OPEN || 0,
                { root: true }
              );
            if (typeof config.events.sport !== 'undefined')
              this.$store.commit(
                "app/setEventSport",
                config.events.sport || {},
                { root: true }
              );
            if (typeof config.events.lottery !== 'undefined')
              this.$store.commit(
                "app/setEventLottery",
                config.events.lottery || {},
                { root: true }
              );
          });
        }
      } else {
        this.$store.commit("auth/setAuthenticated", false, { root: true });
      }
    });
    setTimeout(() => {
      document.getElementsByClassName("wrapper")[0].classList.remove("loading");
    }, 1000);
  },
  computed: {
    ...mapState("app", ["toast", "deposit_open", "notifications"]),
    isRouterLoaded: function () {
      if (this.$route.name !== null) return true;

      return false;
    },
    currentLayout: function () {
      const layout = this.$route.meta.layout || "default";

      return layout + "Layout";
    },
  },
  head: {
    link: [
      // adds config/icons into the html head tag
      //...config.icons.map((href) => ({ rel: 'stylesheet', href }))
    ],
  },
};
</script>

<style lang="scss">
/**
 * Transition animation between pages
 */
.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.2s;
  transition-property: opacity;
  transition-timing-function: ease;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}
.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
.v-card.bank.scb {
  background-color: #582584;
}

.v-card.bank.kbank {
  background-color: #008d36;
}

.v-card.bank.bay {
  background-color: #ffbe00;
}

.v-card.bank.baac {
  background-color: #13007d;
}

.v-card.bank.bbl {
  background-color: #111583;
}

.v-card.bank.cimb {
  background-color: #8a2034;
}

.v-card.bank.gh {
  background-color: #f89828;
}

.v-card.bank.gsb {
  background-color: #f3399c;
}

.v-card.bank.kk {
  background-color: #009dca;
}

.v-card.bank.ktb {
  background-color: #0090d6;
}

.v-card.bank.lhbank *,
.v-card.bank.tmb *,
.v-card.bank.ttb *,
.v-card.bank.tisco * {
  background-color: #eeeeee;
  color: #000000 !important;
}

.v-card.bank.tbank {
  background-color: #f37022;
}

.v-card.bank.uob {
  background-color: #00246a;
}
.notifications {
  position: fixed;
  top: 10px;
  right: 10px;
  width: 300px;
}

.button {
  background: linear-gradient(
    0deg,
    #01336d 0%,
    #000a30 60%,
    #000000 100%
  ) !important;
  border: 1px solid #fff;
  margin-left: 3px;
  margin-right: 3px;
}
.v-btn.gold {
  background-color: rgb(229, 185, 87) !important;
  color: white;
}
.gold--text {
  color: rgb(229, 185, 87) !important;
}
.gold--border::after {
  position: absolute;
  top: -4px;
  bottom: -4px;
  left: -4px;
  right: -4px;
  background: linear-gradient(
    160deg,
    rgb(87, 61, 6),
    rgb(229, 185, 87),
    rgb(87, 61, 6)
  );
  content: "";
  z-index: -1;
  border-radius: 7px;
}

.v-dialog .v-card {
  background-color: rgb(40, 51, 98);
  border-color: rgb(40, 51, 98);
  .v-card__title {
    color: rgb(229, 185, 87) !important;
  }
  .v-card__text {
    color: rgb(229, 185, 87) !important;
  }
}
</style>

