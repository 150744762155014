import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate";

import AppModule from './app.store.js'
import AuthModule from './auth.store.js'
import MemberModule from './member.store.js'
import GameModule from './game.store.js'
import DepositModule from './deposit.store.js'
import WithdrawModule from './withdraw.store.js'
import CouponModule from './coupon.store.js'
import SportModule from './sport.store.js'
import LotteryModule from './lottery.store.js'

Vue.use(Vuex)

const storePath = {
  'auth.hash': (commit) => commit('auth/setHash', '', { root: true }),
  'auth.token': (commit) => commit('auth/setToken', '', { root: true }),
  'app.local': (commit) => commit('app/setLocal', 'th', { root: true }),
  'deposit.lastDepositDate': (commit) => commit('deposit/setLastDepositDate', null, { root: true }),
  'withdraw.lastWithdrawDate': (commit) => commit('withdraw/setLastWithdrawDate', null, { root: true }),
}

const store = new Vuex.Store({
  modules: {
    app: AppModule,
    auth: AuthModule,
    member: MemberModule,
    game: GameModule,
    deposit: DepositModule,
    withdraw: WithdrawModule,
    coupon: CouponModule,
    sport: SportModule,
    lottery: LotteryModule,
    reset: {
      actions: {
        reset ({ commit }) {
          Object.values(storePath).forEach( func => {
            func(commit)
          })
        }
      }
    }
  },
  plugins: [
    createPersistedState({
      key: 'ak',
      paths: Object.keys(storePath)
    })
  ]
})

export default store
