export default {
  MAIN: 'MAIN WALLET',
  SB: 'Sportbook  (AK2)',
  SG: 'Spade Gaming',
  XE: 'XE88 Casino',
  WG: 'Wang Gaming',
  M8: 'Sportbook',
  DG: 'DG Casino',
  IA: 'IA E-SPORT',
  GD: 'GD',
  AB: 'Allbet',
  ZB: 'AK-Gaming',
  AA: 'Sportbook',
  EZ: 'Ezugi Casino',
  CT: 'CT855 Casino',
  WM: 'WM Casino',
  BC: 'Sportbook  (AK1)',
  JK: 'Joker Slot',
  HB: 'Casino (AK3)',
  HO: 'Casino (Hong Bo)',
  AE: 'Casino (AE Sexy)',
  SA: 'Casino (SA Gaming)',
  PG: 'Slot (PG Gaming)',
  AC: 'Slot (TJ126)',
}
