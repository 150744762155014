<template>
  <v-col class="tab text-center" :class="{ active: active }">
    <v-alert class="rounded-circle mx-auto" width="60" height="60" :outlined="!active" :color="active? '#e5b957': '#614f28'">
      <v-icon :color="active? '#ffffff': '#614f28'">{{ icon }}</v-icon>
    </v-alert>
    <span class="white--text">{{ text }}</span>
  </v-col>
</template>

<script>
export default {
  props: {
    active: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String,
      default: 'mdi-chat-outline'
    },
    text: {
      type: String,
      required: true
    }
  },
  data() {
    return {}
  },
  created() {},
  mounted() {},
  methods: {},
  computed: {}
}
</script>

<style scoped>
  .tab {
    border-bottom: 3px solid #614f28;
  }
  .tab.active {
    border-bottom: 4px solid #e5b957 !important;
  }
</style>
