export default {
  required: () => { return value => value===0 || !!value || 'กรุณากรอกข้อมูล' },
  counter: () => { return value => value.length <= 20 || 'ตัวอักษรมากสุด 20 ตัวอักษร' },
  email: () => { return value => {
    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return pattern.test(value) || 'กรุณากรอก อีเมล ให้ถูกต้อง'
  }},
  integer: (min=Number.MIN_SAFE_INTEGER, max=Number.MAX_SAFE_INTEGER) => {
    return value => [
      Number.isInteger(parseInt(String(value))) || 'กรุณากรอก ตัวเลข',
      value >= min || `กรุณากรอกตัวเลขโดยมีค่าน้อยสุดคือ ${min}`,
      value <= max || `กรุณากรอกตัวเลขโดยมีค่ามากสุดคือ ${max}`,
   ].find(r => typeof r === 'string') || true
  },
  number: (min=Number.MIN_SAFE_INTEGER, max=Number.MAX_SAFE_INTEGER) => {
    return value => [
      !isNaN(value) || 'กรุณากรอก ตัวเลข',
      value >= min || 'กรุณากรอกตัวเลขน้อยสุดคือ '+min,
      value <= max || 'กรุณากรอกตัวเลขมากสุดคือ'+max
   ].find(r => typeof r === 'string') || true
  },
  text: (min=0, max=false) => { return value => {
    value = String(value)
    if (min && value.trim().length < min) return `จำนวนตัวอักษรน้อยสุด ${min} ตัวอักษร`
    if (max && value.trim().length > max) return `จำนวนตัวอักษรมากสุด ${max} ตัวอักษร`
    return true
  }},
  numberText: (min=0, max) => { return value => {
    value = String(value)
    if (min && value.trim().length < min) return `จำนวนตัวเลขน้อยสุด ${min} ตัว`
    if (max && value.trim().length > max) return `จำนวนตัวเลขมากสุด ${max} ตัว`
    // eslint-disable-next-line
    if (!value.match(/^([0-9]+\@*)$/g)) return 'กรุณากรอกตัวเลขเท่านั้น'
    return true
  }},
  password: (min=0, max) => { return value => {
    value = String(value)
    if (!value.match(/[a-zA-Z0-9]+/g)) return 'รหัสผ่านรองรับ a-z A-Z 0-9 เท่านั้น'
    if (min && value.length < min) return `รหัสผ่านรองรับอย่างน้อย ${min} ตัว`
    if (max && value.length > max) return `รหัสผ่านรองรับอย่างน้อย ${max} ตัว`
    return true
  }},
  sameAs: (field) => { return value => {
    return value === field || 'ข้อมูลไม่ตรงกัน'
  }}

}
