import { call, api } from '../plugins/axios'
export default {
  namespaced: true,
  state: () => ({
    profile: {},
    balance: {},
    main: 0,
    in_game: 0,
    isSnap: false,
    key: ''
  }),
  getters: {
    profile: (state) => state.profile,
    balance: (state) => state.balance,
    main_balance: (state) => state.main,
    game_balance: (state) => state.in_game,
  },
  mutations: {
    setProfile: (state, value) => state.profile = value,
    setBalance: (state, value) => {
      state.balance = Object.keys(value).map(k => ({ id: k, value: value[k]}))
      const {main, game} = state.balance.reduce((r,n) => {
        if (n.id == 'MAIN') r.main += n.value || 0
        else r.game += n.value
        return r
      }, {main: 0, game: 0})
      state.main = main
      state.in_game = game
    },
    reset:(state) => Object.keys(state).forEach(k => {
      if(Array.isArray(state[k])) state[k] = []
      else if (typeof state[k] == 'object') state[k] = {}
      else if (typeof state[k] == 'number') state[k] = 0
      else if (typeof state[k] == 'boolean') state[k] = false
      else state[k] = ''
    })
  },
  actions: {
    send_otp: async ({ dispatch }, phone) => {
       return await call('member/createOtp', phone)
      .then(({data}) => {
        if (data.error) dispatch('app/showError', { error: { message: data.message } }, { root: true })
        return data
      })
      .catch((error) => {
        if (error.message) dispatch('app/showError', { error: { message: error.message } }, { root: true })
        return error
      })
    },
    check_otp: async ({ dispatch }, data) => {
      return await call('member/checkOtp', data)
      .then((res) => {
        if (res.data.error) dispatch('app/showError', { error: { message: res.data.message } }, { root: true })
        return res.data
      })
      .catch((error) => {
        if (error.message) dispatch('app/showError', { error: { message: error.message } }, { root: true })
        return error
      })
    },
    create_member: async ({ dispatch }, data) => {
      return await call('member/register', data)
      .then((res) => {
        if (res.data.error) dispatch('app/showError', { error: { message: res.data.message } }, { root: true })
        return res.data
      })
    },
    get_balance: async ({ dispatch, rootState }) => {
      if(!rootState.auth || !rootState.auth.hash ) return

      api.defaults.headers.common['hash'] = rootState.auth.hash
      return await call('member/balance')
      .then(({ data }) =>{
        if (data.error) dispatch('app/showError', { error: { message: data.message } }, { root: true })
        return data
      })
      .catch((err) => {
        if(err.error) {
          if (err.message === 'message.authorization'){
            dispatch('app/showError', { error: { message: 'กรุณาล็อคอินอีกครั้ง' } }, { root: true })
            dispatch('auth/logout', null, { root: true })
          }
        }
        return err
      })
    },
    change_password: async ({ dispatch }, data) => {
      return await call('member/changePassword', data)
      .then(({ data }) => {
        if (data.error) dispatch('app/showError', { error: { message: data.message } }, { root: true })
        return data
      })
      .catch(() => {})
    },
    reset: ({commit}) => commit('reset')
  }
}
