import { call } from '../plugins/axios'
import { initializeApp, getApp, getApps } from 'firebase/app';
import { getFunctions, connectFunctionsEmulator, httpsCallable } from 'firebase/functions'
import { getAuth, connectAuthEmulator, onAuthStateChanged, signInWithCustomToken, signOut } from 'firebase/auth'
import { getDatabase, connectDatabaseEmulator, ref, onValue, onChildRemoved, get, child } from "firebase/database";
import { api } from '../plugins/axios'

// eslint-disable-next-line
const firebaseConfig = {
  apiKey: 'AIzaSyAPtXnUfwmldpsTjBig87QXEVG89Padyc4',
  authDomain: 'ak55bet-e3959.firebaseapp.com',
  databaseURL: 'https://ak55bet-e3959-default-rtdb.asia-southeast1.firebasedatabase.app',
  projectId: 'ak55bet-e3959',
  storageBucket: 'ak55bet-e3959.appspot.com',
  messagingSenderId: '954101667178',
  appId: '1:954101667178:web:ee3740f4200d53df41c113',
  measurementId: 'G-MVYKZ3WW1X'
}

const { emulators } = require('../../../firebase.json')

if (getApps().length < 1) {
  initializeApp(firebaseConfig)
}

const app = getApp()
const functions = getFunctions(app, 'asia-southeast1')
export const auth = getAuth(app)
const database = getDatabase(app)

if (process.env.NODE_ENV !== 'production') {
  connectFunctionsEmulator(functions, 'localhost', emulators.functions.port)
  if (location.port !== '8083') connectAuthEmulator(auth, 'http://localhost:' + emulators.auth.port)
  if (location.port !== '8083') connectDatabaseEmulator(database, 'localhost', 19000)
}

export const callw = (name, param) => httpsCallable(functions, name)(param)
export const signInWithToken = (token) => {
  return signInWithCustomToken(auth, token)
}
export const signin = (email, pass) => {
  return call('/login', { email, pass })
  .then(async ({data}) => {
    if (data.token) await signInWithToken(data.token)
    return data
  })
  // console.log('login with email', email, pass)
  // return signInWithEmailAndPassword(auth, email, pass)
}
export const signout = () => signOut(auth)

export const check = () => new Promise((resolve) => onAuthStateChanged(auth, async (user) => {
  resolve(user)
}))


export const initCheckAuth = (callback) => onAuthStateChanged(auth, async (user) => {
  callback(user)
})

const callOnValue = (memberId, key, callback, isHandleRemove = false) => {
  onValue(ref(database, `members/${memberId}/${key}`), (snapshot) => {
    if (snapshot.exists()) {
      const data = snapshot.val()
      callback(key, data)
    }
  })
  if (isHandleRemove) {
    onChildRemoved(ref(database, `members/${memberId}/${key}`), (snapshot) => {
      callback(`remove${key.charAt(0).toUpperCase() + key.slice(1)}`, snapshot.ref.key)
    })
  }
}

export const snapMember = async (member_id, callback) => {

  await get(child(ref(database), `members/${member_id}/profile`))
    .then(async (snapshot) => {
      if (!snapshot.exists()) await api.get('/member/refresh')
    })

  callOnValue(member_id, 'balance', callback)
  callOnValue(member_id, 'profile', callback)
  callOnValue(member_id, 'deposits', callback)
  callOnValue(member_id, 'depositQueues', callback, true)
  callOnValue(member_id, 'withdraws', callback)
  callOnValue(member_id, 'withdrawQueues', callback, true)
  callOnValue(member_id, 'coupons', callback)
  callOnValue(member_id, 'couponQueues', callback, true)
  callOnValue(member_id, 'sports', callback)
  callOnValue(member_id, 'sportQueues', callback, true)
  callOnValue(member_id, 'lotteries', callback)
  callOnValue(member_id, 'lotteryQueues', callback, true)
}

export const snapConfig = (callback) => {
  const memberRef = ref(database, 'app')
  onValue(memberRef, (snapshot) => {
    const data = snapshot.val()
    callback(data)
  })
}

export default app
