import i18n from '../plugins/vue-i18n'
export default {
  namespaced: true,
  state: () => ({
    locale: 'th',
    page_loading: false,
    toast: {
      show: false,
      color: 'black',
      message: '',
      timeout: 3000
    },
    notifications: [],
    isSnap: false,
    deposit_open: false,
    withdraw_open: false,
    sport: {},
    lottery: {},
  }),
  getters: {
    locale: (state) => state.locale,
    page_loading: (state) => state.page_loading,
    deposit_open: (state) => state.deposit_open,
    withdraw_open: (state) => state.withdraw_open,
    notifications: (state) => state.notifications,
    sport: (state) => state.sport,
    lottery: (state) => state.lottery,
  },
  mutations: {
    setLocale: (state, value) => {
      state.locale = value
      i18n.locale = value
    },
    showToast: (state, toast) => {
      const { color, timeout, message } = toast

      state.toast = {
        message,
        color,
        timeout,
        show: true
      }
    },
    hideToast: (state) => {
      state.toast.show = false
    },
    resetToast: (state) => {
      state.toast = {
        show: false,
        color: 'black',
        message: '',
        timeout: 3000
      }
    },
    setPageLoading: (state, value) => state.page_loading = value,
    setDepositOpen: (state, value) => state.deposit_open = value,
    setWithdrawOpen: (state, value) => state.withdraw_open = value,
    pushNotification: (state, notification) => {
      setTimeout(() => {
        state.notifications.push(notification)
        setTimeout(() => {
          const index = state.notifications.findIndex(r => r.id === notification.id)
          notification.show = false
          if (index !== -1) state.notifications.splice(index, 1)
        }, notification.timeOut || 10000)
      }, 2000)
    },
    setEventSport: (state, sport) => state.sport = sport,
    setEventLottery: (state, lottery) => state.lottery = lottery
  },
  actions: {
    showToast: ({ state, commit }, message) => {
      if (state.toast.show) commit('hideToast')

      setTimeout(() => {
        commit('showToast', {
          color: 'black',
          message,
          timeout: 3000
        })
      })
    },

    showError: ({ state, commit }, { message = 'Failed!', error }) => {
      if (state.toast.show) commit('hideToast')

      setTimeout(() => {
        commit('showToast', {
          color: 'error',
          label: message,
          message: error.message,
          timeout: 10000
        })
      })
    },

    showSuccess: ({ state, commit }, message) => {
      if (state.toast.show) commit('hideToast')

      setTimeout(() => {
        commit('showToast', {
          color: 'success',
          message,
          timeout: 3000
        })
      })
    },
    page_loading: ({commit}, loading) => {
      commit('setPageLoading', loading)
    },
  }
}
