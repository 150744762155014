import { call } from '../plugins/axios'
export default {
  namespaced: true,
  state: () => ({
    lastWithdrawDate: null,
    withdraws: [],
    withdrawQueues: [],
  }),
  getters: {
    withdraws: (state) => state.withdraws,
    withdrawQueues: (state) => state.withdrawQueues,
  },
  mutations: {
    setLastWithdrawDate: (state, value) => state.lastWithdrawDate = value,
    setWithdraws: (state, value) => state.withdraws = value,
    setWithdrawQueues: (state, value) => state.withdrawQueues = value,
    removeWithdrawQueue: (state, key) => {
      const index = state.withdrawQueues.findIndex(r => r.key === key)
      if (index !== -1) state.withdrawQueues.splice(index, 1)
    }
  },
  actions: {
    create: async ({ dispatch }, amount) => {
      return await call('withdraw/create', amount)
        .then(({ data }) => {
          if (data.error) dispatch('app/showError', { error: { message: data.message } }, { root: true })

          return data
        })
      .catch(() => {})
    },
    setWithdraws: async ({ commit, state }, values) => {
      const noti = []
      const today = new Date()
      today.setHours(0, 0, 0, 0)
      const dateCheck = new Date(state.lastWithdrawDate || today)
      let lastWithdrawDate = new Date(state.lastWithdrawDate || today)

      values.forEach(v => {
        const dataUpdate = new Date(v.updated_at)
        if (dateCheck.getTime() < dataUpdate.getTime()) {
          if (lastWithdrawDate.getTime() < dataUpdate.getTime()) lastWithdrawDate = new Date(v.updated_at)
          if (v.created_at !== v.updated_at) noti.push(v)
        }
      })

      commit('setWithdraws', values)
      commit('setLastWithdrawDate', lastWithdrawDate)

      noti.forEach(n => {
        let msg = 'มีการเปลี่ยนแปลงข้อมูลการถอนเงินจากระบบ'
        let color = 'yellow'

        if (n.active === 0) {
          msg = `รายการถอนเงิน #${n.id} ถูกยกเลิก`
          color = 'red'
        } else if (n.complete !== 0) {
          msg = `รายการถอน #${n.id} สำเร็จ`
          color = 'green'
        }

        commit('app/pushNotification', {
          key: n.id + '_' + n.updated_at,
          show: true,
          message: msg,
          color: color,
          link: {
            text: 'รายการถอนเงิน',
            to: { name: 'withdraws' }
          }
        }, { root: true })
      })
    }
  }
}
