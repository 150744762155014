import { signInWithToken, signout, signin } from '../plugins/firebase'
import { call } from '../plugins/axios'
//import axios from 'axios'
export default {
  namespaced: true,
  state: () => ({
    authenticated: false,
    token: '',
    hash: ''
  }),
  getters: {
    authenticated: (state) => state.authenticated,
    token: (state) => state.token,
    hash: (state) => state.hash
  },
  mutations: {
    setAuthenticated: (state, value) => state.authenticated = value,
    setToken: (state, value) => state.token = value,
    setHash: (state, value) => state.hash = value
  },
  actions: {
    async login ({dispatch, commit}, data) {
      await signin(`${data.phone}`, data.password)
      .then(async (data) => {
        if(data.error) dispatch('app/showError', { error: { message: data.message } }, { root: true })
        if(data.hash) commit('setHash', data.hash)

        return data
      })
        .catch(({data}) => {
          if(data.error) dispatch('app/showError', { error: { message: data.message } }, { root: true })
        })
    },
    async forgetPassword({ dispatch }, data) {
      await call('/member/forgetPassword', data)
        .then(({data}) => {
          if (!data.error) {
            dispatch('app/showSuccess', data.message, { root: true })
          }
          else {
            dispatch('app/showError', { error: { message: data.message } }, { root: true })
          }
        })
    },
    async loginByToken ({ state, commit, dispatch }) {
      if (state.token.length == 0) return
      await signInWithToken(state.token)
        .then(async () => {
          commit('setAuthenticated', true)
        })
        .catch((error)=> dispatch('app/showError', { error: { message: error.message } }, { root: true }))
    },
    async logout ({ commit, dispatch }) {
      await signout()
      commit('member/reset', false, { root: true })

      commit('setAuthenticated', false)
      commit('setToken', '')
      commit('setHash', '')

      commit('deposit/setDeposits', [] , { root: true })
      commit('deposit/setDepositQueues', [], { root: true })

      commit('withdraw/setWithdraws', [], { root: true })
      commit('withdraw/setWithdrawQueues', [], { root: true })

      dispatch('reset', {}, { root: true })
    }
  }
}
