import { call } from '../plugins/axios'
export default {
  namespaced: true,
  state: () => ({
    coupons: [],
    couponQueues: [],
  }),
  getters: {
    coupons: (state) => state.coupons,
    couponQueues: (state) => state.couponQueues,
  },
  mutations: {
    setCoupons: (state, value) => state.coupons = value,
    removeCoupon: (state, value) => {
      const index = state.coupons.findIndex(r => r.id === value.id)
      if (index !== -1) state.coupons.splice(index, 1)
    },
    setCouponQueues: (state, value) => state.couponQueues = value,
    removeCouponQueue: (state, key) => {
      const index = state.couponQueues.findIndex(r => r.id === key)
      if (index !== -1) state.couponQueues.splice(index, 1)
    }
  },
  actions: {
    useCoupon: async ({ dispatch }, data) => {
      return await call('/coupon/use', data)
        .then(({ data }) => {
          if (data.error) dispatch('app/showError', { error: { message: data.message } }, { root: true })

          return data
        })
        .catch((error) => {
          if (error.message) dispatch('app/showError', { error: { message: error.message } }, { root: true })
          return error
        })
    }
  }
}
