import { call } from '../plugins/axios'
import Vue from  'vue'
export default {
  namespaced: true,
  state: () => ({
    lotteries: [],
    lotteryQueues: {},
  }),
  getters: {
    lotteries: (state) => state.lotteries,
    lotteryQueues: (state) => state.lotteryQueues,
  },
  mutations: {
    setLotteries: (state, value) => state.lotteries = Object.values(value || {}).filter(v => v),
    removeLottery: (state, value) => {
      const index = state.lotteries.findIndex(r => r.id === value.id)
      if (index !== -1) state.lotteries.splice(index, 1)
    },
    setLotteryQueues: (state, value) => state.lotteryQueues = value,
    removeLotteryQueue: (state, key) => {
      Vue.delete(state.lotteryQueues, key)
    },
  },
  actions: {
    async buyLottery({ dispatch }, data) {
      return await call('/event/lottery/buy', data)
        .then(({data}) => {
          if (data.error) dispatch('app/showError', { error: { message: data.message } }, { root: true })
          else dispatch('app/showSuccess', data.message, { root: true })
          return data
        })
        .catch(e => {
          dispatch('app/showError', e, { root: true })
          return e
        })
    },
  }
}
