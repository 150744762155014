import en from '../translations/en'
import th from '../translations/th'

// const supported = ['en', 'th']
let locale = 'th'

// try {
//   // get browser default language
//   const { 0: browserLang } = navigator.language.split('-')

//   if (supported.includes(browserLang)) locale = browserLang
// } catch (e) {
//   // console.error(e)
// }

export default {
  // current locale
  locale,

  // when translation is not available fallback to that locale
  fallbackLocale: 'en',

  // availabled locales for user selection
  availableLocales: [{
    code: 'en',
    flag: 'us',
    label: 'English',
    messages: en
  }, {
    code: 'th',
    flag: 'bath',
    label: 'ภาษาไทย',
    messages: th
  }]
}
