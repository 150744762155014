import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import i18n from './plugins/vue-i18n'
import store from './store'
import router from './routers'
import './plugins/firebase'
import './plugins/clipboard'
import './plugins/common'

import './plugins/register_components'

Vue.config.productionTip = false

import { mask } from 'vue-the-mask'

Vue.directive('mask', mask);

new Vue({
  i18n,
  vuetify,
  store,
  router,
  render: h => h(App),
}).$mount('#app')
