<template>
  <div>
    <v-container fluid class="pa-0">
      <v-row>
        <v-col cols="12">
          <header class="py-2">
            <v-row>
              <v-col cols="12" sm="3" class="text-center text-sm-left">
                <router-link to="/">
                  <img src="/images/logo_ak55bet.png" alt="" class="logo ml-3">
                </router-link>
              </v-col>
              <v-col cols="12" sm="9" class="text-center text-sm-right">
                <div class="d-inline-block login mt-8" v-if="!authenticated">
                  <img src="/images/greendot.gif" alt="" class="logo">
                  <button-register @openLogin="$refs.btnLogin.dialog = true"/>
                  <button-login ref="btnLogin"/>
                </div>
                <div class="d-inline-block wallet" v-else>
                  <div class="d-flex pa-2 align-center">;
                    <img class="mr-2" src="/images/icon/otherwallet_icon.png" alt="" height="25px">
                    <div class="flex-grow-1 white--text">{{ $t('common.main_wallet') }} {{ main_balance.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') }}</div>
                  </div>
                  <div class="d-flex align-center">
                    <img class="mx-2" src="/images/icon/mainwallet_icon.png" alt="" height="25px">
                    <button-balance />
                  </div>
                  <div class="d-flex align-center">
                    <img class="mx-2" src="/images/icon/mainwallet_icon.png" alt="" height="25px">
                    <div class="flex-grow-1 white--text">เหรียญ {{ (profile.coin_total || 0).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') }}</div>
                  </div>
                </div>
                <img src="/images/rectangle.png" alt="" style="height: 70px; vertical-align: middle;" class="mx-4">
                <div class="d-inline-block" style="vertical-align: middle;">
                  <v-menu offset-y >
                    <template v-slot:activator=" { on, attrs } ">
                      <a
                        v-bind="attrs"
                        v-on="on"
                        class="mr-5 menuLanguage"
                      >
                        <img :src="'/images/flag/'+select_language.image+'.png'" height="20" style="vertical-align: middle;" class="mr-3" />
                        <span>{{select_language.text}}</span>
                        <v-icon>mdi-menu-down</v-icon>
                      </a>
                    </template>
                    <v-list>
                      <v-list-item
                        v-for="(item, index) in language"
                        :key="index"
                        @click="changeLanguage(item.text, item.image, item.value)"
                      >
                        <v-list-item-icon height="30px" width="30px">
                          <v-img :src="'/images/flag/'+item.image+'.png'" width="30px"></v-img>
                        </v-list-item-icon>
                        <v-list-item-title> {{ item.text }} </v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                  <div class="text-left mt-2" v-if="authenticated">
                    <button-logout />
                  </div>
                </div>
              </v-col>
            </v-row>
          </header>
        </v-col>
      </v-row>
    </v-container>
    <v-main class="defaultBg" style="height: 100vh">
      <router-view @openLogin="dialog = true"/>
    </v-main>
    <footer>
      <p class="text-center pt-6 gold--text ">Copyright 2021 © AK55BET | สงวนลิขสิทธิ์</p>
    </footer>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import ButtonRegister from '../components/common/ButtonRegister.vue'
import ButtonLogin from '../components/common/ButtonLogin.vue'
import ButtonLogout from '../components/common/ButtonLogout.vue'
import ButtonBalance from '../components/common/ButtonBalance.vue'

export default {
  components: {
    ButtonRegister,
    ButtonLogin,
    ButtonLogout,
    ButtonBalance,
  },
  data() {
    return {
      language: [
        { text: 'Thailand', image: 'thai', value: 'th'},
        //{ text: 'English', image: 'english', value: 'en' },
      ],
    }
  },
  created() {},
  mounted() {},
  methods: {
    ...mapActions('app', ['setLocale']),
    changeLanguage (text, image, locale) {
      //this.setLocale(locale)
      this.$i18n.locale = locale
    }
  },
  computed: {
    ...mapGetters('auth', ['authenticated']),
    ...mapGetters('member', ['main_balance', 'profile']),
    select_language () {
      const lang = this.language.find(r => r.value == this.$i18n.locale)
      if (!lang) return {}
      return lang
    }
  }
}
</script>

<style lang="scss" scoped>
.body {
   font-family: "Kanit";
}
header::v-deep { background: linear-gradient(0deg, #01336d 0%, #000a30 60%, #000000 100%) !important;
  .logo { height: 70px; width: auto; }
  .login {
    img { height: 15px; vertical-align: middle; margin-right: 10px; }
  }
  .wallet { font-size: 0.9em; vertical-align: middle; width: 250px;
    img { vertical-align: middle; }
  }
}
.btnLogin { background: linear-gradient(#f1eb4d, #ecad00); border: 1px solid #bcbcbc; color: #000; border-radius: 3px; padding: 5px 0; display: block; text-align: center; }
.menuLanguage { color: #fff; font-size: 1.1em; }
.modalLoginSucess {
  p { font-size: 1.1em; text-align: center; margin-bottom: 0; }
}

</style>
