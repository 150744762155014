<template>
  <v-menu
    v-model="open"
    :close-on-content-click="false"
    offset-y
    left
  >
    <template v-slot:activator=" { on, attrs } ">
      <div class="flex-grow-1 d-flex justify-end">
        <a
          v-bind="attrs"
          v-on="on"
          class="white--text px-2"
        >
          {{ $t('game.balance') }} {{ game_balance.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}}
        </a>
      </div>

    </template>
    <v-list width="300">
      <v-list-item class="d-flex">
        <v-btn color="primary" class="flex-grow-1" @click="credit_move" :loading="isLoading" :disabled="isLoading">move to main wallet</v-btn>
      </v-list-item>
      <v-list-item
        v-for="(item, index) in balance_list"
        :key="index"
        style="min-height: auto;"
        :disabled="isLoading"
      >
        <div style="width: 100%; font-size: 0.9em;">
          <div class="d-inline-block" style="width: 50%;">{{ balances[item.id] || item.id }} </div>
          <div class="d-inline-block text-right" style="width: 45%;">{{ item.value }} </div>
        </div>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import balances from '../../configs/balance'
export default {
  data() {
    return {
      isLoading: false,
      open: false,
      balances: {}
    }
  },
  created() {
    this.balances = balances
  },
  mounted() {
    window.addEventListener('focus', this.windowFocus)
  },
  async deactivated () {
    console.log('beforeUnmount')
    window.removeEventListener('focus', this.windowFocus)
  },
  methods: {
    ...mapActions('member', ['get_balance']),
    ...mapActions('game', ['creditMove']),
    async credit_move() {
      this.isLoading = true
      await this.creditMove()
      this.isLoading = false
    },
    async windowFocus() {
      if (!this.authenticated) return
      const sleep = () => new Promise(resolve => setTimeout(resolve, 1000))
      const check = async () => {
        let returnData = false
        for(const i in [...Array(3).keys()]) {
          if (this.hash.length) {
            returnData = true
            break
          }
          await sleep(i)
        }
        return returnData
      }
      if (await check()) {
        this.get_balance().then((data) => {
          if(data.error && data.message == 'message.authorization') {
            this.$router.push({name: 'home'})
          }
        })
      }
    }
  },
  watch: {
    async open(value) {
      if (value) {
        this.isLoading = true
        await this.get_balance()
        this.isLoading = false
      }
    }
  },
  computed: {
    ...mapGetters('member', ['balance', 'game_balance']),
    ...mapGetters('auth', ['authenticated', 'hash']),
    balance_list () {
      if (this.balance && this.balance.filter) return this.balance.filter(r => r.id !== 'MAIN')
      else return []
    }
  }
}
</script>

<style scoped>
  .moneyInGame { background: linear-gradient(#0969c5, #000a2d); border: 1px solid #fff; padding: 5px 0; width: 100%; display: block; text-align: center; border-radius: 5px; color: #fff; }
</style>
