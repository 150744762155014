<template>
  <div>
    <v-menu offset-y close-on-content-click>
      <template v-slot:activator="{ on, attrs }">
        <a
          class="white--text rounded-pill red blue pa-1"
          v-bind="attrs"
          v-on="on"
        >
          <v-icon small class="mr-1 white--text">mdi-account</v-icon>
          <span style="font-size: 0.8em;">{{ profile.ufa_user || 'loading' }}</span>
        </a>
      </template>
      <v-list>
        <v-list-item link
          @click="$router.push({name: 'member-change-password'})"
          :disabled="$route.name === 'member-change-password'"
        >
          <v-list-item-title>{{ $t('member.change_password') }}</v-list-item-title>
        </v-list-item>
        <v-list-item link
          @click="$router.push({name: 'game'})"
          :disabled="$route.name === 'game'"
        >
          <v-list-item-title>{{ $t('common.game') }}</v-list-item-title>
        </v-list-item>
        <v-list-item link
          @click="$router.push({name: 'member-deposit'})"
          :disabled="$route.name === 'member-deposit'"
        >
          <v-list-item-title>{{ $t('deposit.text') }}</v-list-item-title>
        </v-list-item>
        <v-list-item link
          @click="$router.push({name: 'member-deposit-inform'})"
          :disabled="$route.name === 'member-deposit-inform'"
        >
          <v-list-item-title>{{ $t('deposit_inform.text') }}</v-list-item-title>
        </v-list-item>
        <v-list-item link
          @click="$router.push({name: 'member-withdraw'})"
          :disabled="$route.name === 'member-withdraw'"
        >
          <v-list-item-title>{{ $t('withdraw.text') }}</v-list-item-title>
        </v-list-item>
        <v-list-item link
          @click="$router.push({name: 'member-coupon'})"
          :disabled="$route.name === 'member-coupon'"
        >
          <v-list-item-title>{{ $t('coupon.text') }}</v-list-item-title>
        </v-list-item>
        <v-list-item link>
          <v-list-item-title @click="logout_dialog = true">{{ $t('common.logout') }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <v-dialog v-model="logout_dialog" width="350">
      <v-card color="#283362" class="pt-5">
        <v-card-text class="text-center white--text">
          <h2>{{ $t('message.logout_confirm') }}</h2>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red" @click="logout_dialog = false" outlined>
            {{ $t('common.cancel') }}
          </v-btn>
          <v-btn color="#e5b957"  @click="logout">
            {{ $t('common.submit') }}
          </v-btn>
        </v-card-actions>
        <v-spacer></v-spacer>
      </v-card>
    </v-dialog>
  </div>

</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  data() {
    return {
      logout_dialog: false
    }
  },
  created() {},
  mounted() {},
  methods: {
    ...mapActions('auth', {'member_logout': 'logout'}),
    async logout () {
      await this.member_logout()
        .then(() => {
          this.$router.push({ name: 'home' })
        })
    }
  },
  computed: {
    ...mapGetters('member', ['profile'])
  }
}
</script>

<style>

</style>
