import { call } from '../plugins/axios'
export default {
  namespaced: true,
  state: () => ({

  }),
  getters: {
  },
  mutations: {
  },
  actions: {
    async launch ({ dispatch }, data) {
      return await call('/game/launch', data)
        .then(({data}) => {
          if (data.error) return dispatch('app/showError', { error: { message: data.message } }, { root: true })
          return data
        })
        .catch((error) => {
          if (error.message) dispatch('app/showError', { error: { message: error.message } }, { root: true })
          return error
        })
    },
    async creditMove({commit, dispatch}) {
      return await call('/game/creditMove')
        .then(({data}) => {
          if (data.error) return dispatch('app/showError', { error: { message: data.message } }, { root: true })
          if (data.balance) return commit('member/setBalance', data.balance, { root: true })

          return data
        })
        .catch((error) => {
          if (error.message) dispatch('app/showError', { error: { message: error.message } }, { root: true })
          return error
        })
    }
  }
}
