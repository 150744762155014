export default {
  common: {
    cancel: 'Cancel',
    submit: 'OK',
    main_wallet: 'main wallet',
    back: 'back',
    bath: 'baht'
  },

  message: {
    accountant: 'Account type product',
    auth_fail: 'Unable to login. Please login again.',
    casino: 'Casino Products',
    casino_text: 'Good online casino',
    deposit_send_success: 'Deposit added successfully',
    game: 'Game type product',
    history: 'History',
    invalid: 'Please enter information again',
    logout_confirm: 'Want to log out?',
    member_not_found: 'No user account found in the system.',
    not_found: 'There was an error in the system, please check again.',
    not_visit: 'Unable to access the page. Please login again',
    phone_already_exist: 'The user already exists',
    spots: 'Sports products',
    system_fail: 'An error was encountered in the system',
    unauthorized: 'Please login again',
    withdraw_send_success: 'The item added, withdrawn successfully',
    otp_mismatch: 'Please enter a valid otp',
    waiting_again: 'Please try again after this {time} minutes',
    otp_waiting_again: 'Please try again after {time} minutes',
    can_withdraw: 'Able to withdraw {amount} baht',
    withdraw_waiting_queue_free: 'Unable to withdraw because there is still pending.'
  },
  spots: {
    text: 'Sports'
  },
  casino: {
    text: 'Casino'
  },
  accountant: {
    text: 'Account'
  },
  history: {
    text: 'History'
  },

  login: {
    text: 'Login',
    phone: 'phone number',
    password: 'password'
  },

  register: {
    text: 'Subscribe'
  },

  home: {
    msg: 'Good online casino'
  },

  deposit: {
    text: 'Deposit',
    history: 'Deposit history',
    id: 'order',
    date: 'date time',
    amount: 'amount',
    comment: 'comment',
    status: 'status',
    agent_bank_name: 'Bank',
    agent_bank_number: 'Deposit account number',
    deposit_date: 'Deposit datetime',
    slip_bank: 'Deposit slip',
    notification: 'Deposit notification',
    not_confirm: 'Verifying data',

    cancel: 'Cancel',
    success: 'success',
    confirm: 'Pending',
    waiting: 'Pending'
  },
  withdraw: {
    text: 'Withdraw money',
    history: 'Withdrawal history',
    id: 'order',
    date: 'date time',
    amount: 'Withdrawal amount',
    comment: 'comment',
    status: 'status',
    notification: 'Withdrawal notification',
    note: '* Once withdrawn will transfer all game balance back to main wallet',
    not_complete: 'Verifying data',
    complete: 'success',
    type1: 'There is an outstanding bill. Please contact the staff',
    type2: 'The transaction failed. Please contact the staff',

    cancel: 'Cancel',
    success: 'success',
    confirm: 'Pending',
    waiting: 'Pending'


  },

  game: {
    text: 'Game',
    balance: 'Game Balance'
  },
  member: {
    bank_name: 'Bank',
    bank_number: 'account number',
    bank_user: 'Account Name'
  },
  bank: {
    scb: 'Siam Commercial Bank',
    bay: 'Krungsri Ayutthaya',
    bbl: 'Bangkok',
    cimb: 'CIMB Thai',
    gsb: 'Government Savings Bank',
    kbank: 'Kasikornthai',
    kk: 'Kiatnakin',
    ktb: 'Krungthai',
    lhbank: 'Land and House Bank',
    tbank: 'TBank',
    tmb: 'Thanachat Bank',
    uob: 'UOB',
    baac: 'BAAC',
    ghb: 'Hospital Bank',
    ttb: 'TTB Bank',
    tisco: 'TISCO Bank'
  },

  $vuetify: {
    noDataText: 'no data',
    timePicker: {
      am: 'AM',
      pm: 'PM'
    }
  }
}
