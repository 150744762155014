var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"label-text"},[_c('div',{staticClass:"d-flex flex-column flex-md-row align-md-center my-2"},[_c('b',{class:{
        'mb-xs-0': true,
        'mb-md-5':
          _vm.showInputOrText == 'input' && _vm.$attrs['hide-defaults'] !== '',
      },style:({
        'min-width': _vm.labelWidth + 'px',
        'max-width': _vm.$vuetify.breakpoint.smAndDown
          ? '100%'
          : _vm.labelWidth + 'px',
      })},[_vm._v(" "+_vm._s(_vm.$attrs["label_text"] || "no label")+" ")]),(_vm.showInputOrText === 'input')?_c('div',{staticClass:"flex-grow-1 d-flex"},[(_vm.$slots['default'])?_vm._t("default"):(!_vm.$attrs['items'])?_c('v-text-field',_vm._g(_vm._b({attrs:{"hide-details":_vm.$attrs['hide-defaults'] == '',"background-color":!_vm.$attrs['outlined'] ? '' : _vm.color},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [_vm._t("append-outer")]},proxy:true},{key:"append",fn:function(){return [_vm._t("append")]},proxy:true}],null,true)},'v-text-field',_vm.$attrs,false),_vm.$listeners)):_c('v-select',_vm._g(_vm._b({attrs:{"hide-details":_vm.$attrs['hide-defaults'] == '',"background-color":!_vm.$attrs['outlined'] ? '' : _vm.color},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [_vm._t("append-outer")]},proxy:true}],null,true)},'v-select',_vm.$attrs,false),_vm.$listeners))],2):_c('div',{staticClass:"flex-grow-1 d-flex align-center"},[_c('v-sheet',_vm._b({class:[
          { errorx: !!_vm.$attrs['error-messages'] },
          'px-1',
          'py-1',
          'flex-grow-1' ],style:({ 'min-height': _vm.valueHeight + 'px' }),attrs:{"rounded":"","color":_vm.color,"dark":""}},'v-sheet',_vm.$attrs,false),[_c('div',{staticClass:"d-flex mx-1 align-center"},[_vm._t("default"),(!_vm.$slots['default'])?_c('span',{staticClass:"flex-grow-1 noselect"},[_vm._v(_vm._s(_vm.value_text))]):_vm._e(),_vm._t("append")],2)]),_vm._t("append-outer")],2)])])}
var staticRenderFns = []

export { render, staticRenderFns }