
import Vue from 'vue'

Vue.prototype.$date = {
  now: (timeZone = 'Asia/Bangkok') => {
    const d = new Date().toLocaleString('en-GB', { timeZone }).split(/\/|, | |:/)
    const dateString = `${d[2]}-${('00' + d[1]).slice(-2)}-${('00' + d[0]).slice(-2)}T${('00' + d[3]).slice(-2)}:${('00' + d[4]).slice(-2)}:${('00' + d[5]).slice(-2)}.700Z`
    return new Date(dateString)
  },
  toString: (date, timeZone = 'Asia/Bangkok') => {
    // const t = new Date('2022-03-22T19:07:47').toLocaleString("en-GB", {timeZone});
    const d = new Date()
    d.setHours( d.getHours()+(d.getTimezoneOffset()/-60) )
    return new Date((typeof date === "string" ? new Date(date) : date).toLocaleString("en-US", {timeZone}));

  }
}

Vue.prototype.$createReset = ($this, field, data) => {
  if (!$this["$$reset"]) $this.$$reset = {};
  if (typeof data == "object") {
    $this.$$reset[field] = () => {
      $this.$set($this, field, JSON.parse(JSON.stringify(data)));
    };
    return { [field]: JSON.parse(JSON.stringify(data)) };
  } else
    $this.$$reset[field] = () => {
      $this.$set($this, field, data);
    };
  return { [field]: data };
}
