import Vue from 'vue'
import Router from 'vue-router'
import { check } from '../plugins/firebase'

Vue.use(Router)

export const routes = [
  {
    path: '/', name: 'home',
    component: () => import( '@/pages/Home.vue'),
  },
  {
    path: '/contact', name: 'contact',
    component: () => import( '@/pages/Contact.vue'),
  },
  {
    path: '/game', name: 'game',
    component: () => import( '@/pages/member/Dashboard.vue'),
    meta: { auth: true }
  },
  { path: '/dashboard', name: 'member-dashboard',
    redirect: '/game',
  },
  { path: '/deposit', name: 'member-deposit',
    component:  () => import( '@/pages/member/Deposit.vue' ),
    meta: { type: 'deposit', auth: true }
  },
  { path: '/deposit-inform', name: 'member-deposit-inform',
    component:  () => import( '@/pages/member/Deposit.vue' ),
    meta: { type: 'inform', auth: true }
  },
  { path: '/withdraw', name: 'member-withdraw',
    component:  () => import( '@/pages/member/Withdraw.vue'),
    meta: { auth: true }
  },
  { path: '/coupon', name: 'member-coupon',
    component:  () => import( '@/pages/member/Coupon.vue'),
    meta: { auth: true }
  },
  { path: '/change-password', name: 'member-change-password',
    component:  () => import( '@/pages/member/ChangePassword.vue'),
    meta: { auth: true }
  },
  { path: '/sport', name: 'member-sport',
    component:  () => import( '@/pages/member/Sport.vue'),
  },
  { path: '/lottery', name: 'member-lottery',
    component:  () => import( '@/pages/member/Lottery.vue'),
  },
  {
    path: '*',
    name: 'error',
    component: () => import( '@/pages/error/NotFoundPage.vue'),
    meta: {
      layout: 'error'
    }
  }

]

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL || '/',
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) return savedPosition

    return { x: 0, y: 0 }
  },
  routes
})

const checkAuth = async () => {
  const vuex = router.app.$store

  if (vuex.getters['auth/authenticated']) {
    return true
  }
  return await check()
  .then(async (user) => {
    if (!user) return false
    if (!vuex.getters['member/profile']['active']) {
      const sleep = (time) => new Promise((resolve) => {
        setTimeout(resolve, time)
      })
      for(let i=0; i<1000; i++) {
        await sleep(1000)
        if (vuex.getters['member/profile']['active']) {
          break
        }
      }
    }
    vuex.commit('auth/setAuthenticated', !!user, { root: true })
    return true
  })
  .catch(() => {
    return false
  })
}
/**
 * Before each route update
 */

router.beforeEach(async (to, from, next) => {
  if (to.meta.where) {
    let pass = true
    Object.keys(to.meta.where).forEach((key) => {
      if (pass && !!to.params[key]) pass = to.meta.where[key](to.params[key])
    })
    if (!pass) return next({ name: 'error' })
  }

  const isAuth = await checkAuth()
  if (to.meta.auth && !isAuth) {
    // router.app.$store.dispatch('app/showError', { error: { message: 'message.not_visit' } }, { root: true })
    if (to.name != 'login') return next({ name: 'home' })
    return
  }

  return next()
})

  /**
   * After each route update
   */
  //router.afterEach(async (to, from) => {
    // if (to.name === 'logout') {
    //   const vuex = router.app.$store
    //   vuex.commit('auth/setToken', null, { root: true })
    //   vuex.commit('auth/setUser', {}, { root: true })
    //   vuex.commit('auth/authenticated', false, { root: true })
    //   router.push('login')
    // }
  //})

export default router
