import { call } from '../plugins/axios'
import Vue from  'vue'
export default {
  namespaced: true,
  state: () => ({
    sports: [],
    sportQueues: [],
  }),
  getters: {
    sports: (state) => state.sports,
    sportQueues: (state) => state.sportQueues,
  },
  mutations: {
    setSports: (state, value) => state.sports = value,
    removeSport: (state, value) => {
      const index = state.sports.findIndex(r => r.id === value.id)
      if (index !== -1) state.sports.splice(index, 1)
    },
    setSportQueues: (state, value) => state.sportQueues = value,
    removeSportQueue: (state, key) => {
      Vue.delete(state.sportQueues, key)
    }
  },
  actions: {
    async buySport({ dispatch }, data) {
      return await call('/event/sport/buy', data)
        .then(({data}) => {
          if (data.error) dispatch('app/showError', { error: { message: data.message } }, { root: true })
          else dispatch('app/showSuccess', data.message, { root: true })
          return data
        })
        .catch(e => {
          dispatch('app/showError', e, { root: true })
          return e
        })
    }
  }
}
