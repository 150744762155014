import { call } from '../plugins/axios'
export default {
  namespaced: true,
  state: () => ({
    lastDepositDate: null,
    deposits: [],
    depositQueues: [],
    open: false,
  }),
  getters: {
    deposits: (state) => state.deposits,
    depositQueues: (state) => state.depositQueues,
  },
  mutations: {
    setLastDepositDate: (state, value) => state.lastDepositDate = value,
    setDeposits: (state, value) => state.deposits = value,
    removeDeposit: (state, value) => {
      const index = state.deposits.findIndex(r => r.id === value.id)
      if (index !== -1) state.deposits.splice(index, 1)
    },
    setDepositQueues: (state, value) => state.depositQueues = value,
    removeDepositQueue: (state, key) => {
      const index = state.depositQueues.findIndex(r => r.key === key)
      if (index !== -1) state.depositQueues.splice(index, 1)
    }
  },
  actions: {
    create: async ({ dispatch }, amount) => {
      return await call('/deposit/create', amount)
        .then(({ data }) => {
          if (data.error) dispatch('app/showError', { error: { message: data.message } }, { root: true })

          return data
        })
        .catch((error) => {
          if (error.message) dispatch('app/showError', { error: { message: error.message } }, { root: true })
          return error
        })
    },
    createInform: async ({ dispatch }, data) => {
      return await call('/deposit/createInform', data)
        .then(({ data }) => {
          if (data.error) dispatch('app/showError', { error: { message: data.message } }, { root: true })

          return data
        })
        .catch((error) => {
          if (error.message) dispatch('app/showError', { error: { message: error.message } }, { root: true })
          return error
        })
    },
    uploadSlip: async ({ dispatch }, data) => {
      return await call('/deposit/uploadSlip', data)
        .then(({ data }) => {
          if (data.error) dispatch('app/showError', { error: { message: data.message } }, { root: true })

          return data
        })
        .catch((error) => {
          if (error.message) dispatch('app/showError', { error: { message: error.message } }, { root: true })
          return error
        })
    },
    cancel: async ({ dispatch }, id) => {
      return await call('/deposit/cancel', id)
      .then(({ data }) => {
        if (data.error) dispatch('app/showError', { error: { message: data.message } }, { root: true })

        return data
      })
      .catch((error) => {
        if (error.message) dispatch('app/showError', { error: { message: error.message } }, { root: true })
        return error
      })
    },
    cancelCDM: async ({ dispatch }) => {
      return await call('/deposit/cancelCDM')
      .then(({ data }) => {
        if (data.error) dispatch('app/showError', { error: { message: data.message } }, { root: true })

        return data
      })
      .catch((error) => {
        if (error.message) dispatch('app/showError', { error: { message: error.message } }, { root: true })
        return error
      })
    },
    setDeposits: async ({ commit, state }, values) => {
      const noti = []
      const today = new Date()
      today.setHours(0, 0, 0, 0)
      const dateCheck = new Date(state.lastDepositDate || today)
      let lastDepositDate = new Date(state.lastDepositDate || today)
      values.forEach(v => {
        const dataUpdate = new Date(v.updated_at)
        if (dateCheck.getTime() < dataUpdate.getTime()){
          if (lastDepositDate.getTime() < dataUpdate.getTime()) lastDepositDate =  new Date(v.updated_at)
          if (v.created_at !== v.updated_at) noti.push(v)
        }
      })
      commit('setDeposits', values)
      commit('setLastDepositDate', lastDepositDate)
      noti.forEach(n => {
        let msg = 'มีการเปลี่ยนแปลงข้อมูลการฝากเงินจากระบบ'
        let color = 'yellow'

        if (n.can_upload) {
          msg = `รายการฝากเงิน #${n.id} สามารถอัพโหลดหลักฐานการฝากเงินได้แล้ว`
        } else if (n.active === 0) {
          msg = `รายการฝากเงิน #${n.id} ถูกยกเลิก`
          color = 'red'
        } else if (n.complete !== 0) {
          msg = `รายการฝาก #${n.id} สำเร็จ`
          color = 'green'
        }

        commit('app/pushNotification', {
          key: n.id + '_' + n.updated_at,
          show: true,
          message: msg,
          color: color,
          link: {
            text: 'รายการฝากเงิน',
            to: { name: 'deposits' }
          }
        }, { root: true })
      })
    }
  }
}
